import { Bar, mixins } from 'vue-chartjs'
import * as moment from 'moment'
const { reactiveProp } = mixins
import axios from 'axios'

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options:{
      type: Object,
      default: null
    }
  },
  mounted (){
    this.renderChart(this.chartData, this.options)
  }
}
