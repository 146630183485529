<template>
    <vs-row>
        <vs-col class="mb-base" v-if="info">
            <span class="mr-4">{{$t('profile.name')+'：'}}{{ info.name }}</span>
            <span class="mr-4">{{$t('sex')+'：'}}{{ getSex(info.sex) }}</span>
            <span class="mr-4">{{$t('age')+'：'}}{{ getAge(info.birthday) }}</span>
            <span class="mr-4">{{$t('Height')+'：'}}{{ info.height }}</span>
            <span class="mr-4">{{$t('Weight')+'：'}}{{ info.weight }}</span>
            <span class="mr-4">BMI：{{ info.BMI }}</span>
        </vs-col>
        <!-- <vs-col class="mb-base py-4 px-6 bg-white"> -->
            
            <!-- <BarChart :chart-data="chartData" :options="options" v-if="chartData.labels.length>0"></BarChart> -->
        <!-- </vs-col> -->
        <vs-col class="mb-base">
            <div class="flex text-center">
                <div class="px-4 flex w-auto mb-2">
                    <div class="w-32">{{$t('components.specAgent.date')}}</div>
                    <div class="w-24">{{$t('dietmgmt.Carbohydrate')}}</div>
                    <div class="w-24">{{$t('dietmgmt.Protein')}}</div>
                    <div class="w-24">{{$t('dietmgmt.Fat')}}</div>
                    <div class="w-24">{{$t('dietmgmt.Status')}}</div>
                    <!-- <div class="w-24">{{$t('dietmgmt.Total')}}</div> -->
                </div>
            </div>
            <div class="px-4 text-center">
                
                <div class="flex w-auto mb-2" v-for="(item,index) in dietChartData" :key="index">
                    <div class="w-32">{{ item.date }}</div>
                    <div class="w-24" v-if="item.carbohydrate">{{ item.carbohydrate }}</div>
                    <div class="w-24" v-else>{{ }}</div>
                    <div class="w-24" v-if="item.protein">{{ item.protein }}</div>
                    <div class="w-24" v-else>{{ }}</div>
                    <div class="w-24" v-if="item.fat">{{ item.fat }}</div>
                    <div class="w-24" v-else>{{ }}</div>
                        <span class="px-4" v-if="item.done == 'na'">{{$t('dietmgmt.NoData')}}</span>
                        <span v-else-if="item.done == 'undone'" class="px-4 text-danger cursor-pointer hover:underline hover:font-semibold" @click="openPopup(info.mid, item.date, 0)">{{$t('dietmgmt.Analyze')}}</span>
                        <span v-else style="color: #003f8e;" class="px-4 cursor-pointer hover:underline hover:font-semibold" @click="openPopup(info.mid, item.date, 0)">{{"已分析"}}</span>
                </div>
            </div>
        </vs-col>
        <diet-popup :dietPopupActive="dietPopupActive" :payload="payload" @closePopup="closePopup"></diet-popup>
    </vs-row>
</template>
<script>
import { fetchDietMemberDetail, fetchDietChartData } from '@/api/user'
import BarChart from '@/components/charts/BarChart.js'
import DietPopup from '../components/dietPopup.vue';
export default {
    components:{
        BarChart,
        DietPopup,
        // Bar
    },
    data() {
        return {
            pid: null,
            info: null,
            logs: null,
            page: 1,
            limit: 30,
            dietPopupActive: false,
            payload: {},
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: '卡路里',
                        backgroundColor: '#7f9fc6',
                        data: []
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        title: {
                            display: true,
                            text: '卡路里'
                        },
                        min: 0,
                        max: 3000,
                        ticks: {
                            stepSize: 200
                        }
                    }],
                    // xAxes: [{
                        // type: 'time',
                        // time: {
                        //     displayFormats: {
                        //         day: 'MMM D'
                        //     }
                        // }
                        // ticks: {
                        //     stepSize: 50
                        // }
                        // ticks: {
                        //     // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                        //     callback: function(val, index) {
                        //         // Hide every 2nd tick label
                        //         return index % 5 === 0 ? val : '';
                        //     },
                        //     color: 'red',
                        // }
                        // time: {
                        //     displayFormats: {
                        //         quarter: 'MMM YYYY'
                        //     }
                        // }
                    // }]
                }
            }
        }
    },
    created() {
        this.pid = this.$route.params.pid
        this.fetchDietMemberDetail()
        this.fetchDietChartData(30)
    },
    computed: {
        partner_id() {
            return this.$store.getters.partner
        },
        dietChartData() {
        return this.$store.getters.dietChartData
         },
    },
    methods: {
        // 飲食管理 取會員詳細資料
        fetchDietMemberDetail(){
            let payload = {
                "spid": this.pid,
                "page": this.page,
                "limit": this.limit
            }
            fetchDietMemberDetail(payload).then(res => {
                this.info = _.cloneDeep(res.data.data[0])
                // this.logs = _.cloneDeep(res.data.logs[0].data)
                // var _self = this
                // _.forEach(_self.logs, function(v,i){
                //     _self.logs[i].data = _.orderBy(v.data, ['date'], ['desc'])
                // })
            })
        },
        // 飲食管理 取會員圖表資料 week:range->7 month->30
        fetchDietChartData(range){
            let payload = {
                "spid": this.pid
            }
            this.$store.dispatch('fetchDietChartData',payload)
            let temp = this.dietChartData
            this.dietdoctor = this.dietChartData
            temp = _.sortBy(temp, [function(o) { return o.date; }]);
            // if(range == 365){
            //     new today = new Date()
            //     today.getFullYear()
            //     this.chartData.labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            // }
            temp.forEach((v,i) => {
                if(365-i > range) return
                let day_list = ['日', '一', '二', '三', '四', '五', '六'];
                let d = new Date(v.date)
                this.chartData.labels = _.concat(this.chartData.labels, v.date.substr(5) + ' (' + day_list[d.getDay()] + ')')
                this.chartData.datasets[0].data = _.concat(this.chartData.datasets[0].data, v.carbohydrate,v.protein,v.fat)
            });
        },
        // 取性別
        getSex(val){
            if(val==1){
                return this.$tc('apptHistory.male')
            }else if(val==2){
                return this.$tc('apptHistory.female')
            }else{
                return '-'
            }
        },
        // 取年齡
        getAge(val){
            if (val === '0000-00-00' || !val) {
                return '--'
            } else {
                const ageMS = Date.parse(Date()) - Date.parse(val)
                const age = new Date()
                age.setTime(ageMS)
                const ageYear = age.getFullYear() - 1970
                return ageYear
            }
        },
        // 開啟 飲食分析視窗
        openPopup(mid, date, type){
            this.payload = {
                "mid": mid,
                "date": date,
                "type": type
            }
            document.body.style.overflow = 'hidden';
            this.dietPopupActive = true
        },
        // 關閉 飲食分析視窗
        closePopup(v){
            this.dietPopupActive = v
            document.body.style.overflow = 'visible';
            this.fetchDietMemberDetail()
        },
        // 每日數值加總
        dailyTotal(am, pm, night, other){
            let total = 0
            if(!isNaN(am)) total += am
            if(!isNaN(pm)) total += pm
            if(!isNaN(night)) total += night
            if(!isNaN(other)) total += other
            return total
        },
    },
}
</script>